<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col cols="3">Número do CPF:</v-col>
      <v-col cols="9">{{ cpf }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">Nome do Cliente:</v-col>
      <v-col cols="9">{{ nomeCompleto }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Produtos Selecionados</div>
      </v-col>
    </v-row>

    <v-row no-gutters class="form-row">
      <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']">Produto</v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']"
        >Número da Proposta</v-col
      >
    </v-row>

    <v-row :key="item.text" class="form-row" no-gutters v-for="item in produtos">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses">{{ item.text }}</v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses">{{ item.proposta }}</v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="text-right my-3">
        <a id="btn-imprimir" @click="imprimir" class="btn-azul mr-2">Imprimir Formulário</a>
        <a id="btn-fechar" @click="fechar" class="btn-azul">Fechar</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { mapAvaliacaoRiscoFields } from "../../../store/modules/caixaqui-avaliacao-risco";
import { mapActions } from "vuex";

export default {
  name: "CaixaquiAvaliacaoRiscosProdutosSelecProposta",

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  }),
  computed: {
    ...mapAvaliacaoRiscoFields([
      "cpf",
      "nomeCompleto",
      "produtos.creditoImobiliario",
      "produtos.consignacao",
      "produtos.cartaoCredito",
      "produtos.contaChequeAzul",
      "produtos.consigPortabilidade",
      "produtos.chequeAzulIsolado"
    ]),
    produtos() {
      return [
        { text: "Crédito Imobiliário", value: this.creditoImobiliario, proposta: "00000001" },
        { text: "Consignação", value: this.consignacao, proposta: "00000002" },
        { text: "Cartão de Crédito", value: this.cartaoCredito, proposta: "00000003" },
        { text: " Conta com Cheque Azul(CROT)", value: this.contaChequeAzul, proposta: "00000004" },
        { text: "oConsignação Prtabilidade", value: this.consigPortabilidade, proposta: "00000005" },
        { text: "Cheque Azul Isolado", value: this.chequeAzulIsolado, proposta: "00000006" }
      ].filter(produto => produto.value);
    }
  },
  methods: {
    ...mapActions("controller", ["menuSair"]),
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    imprimir() {},
    fechar() {
      this.menuSair({ router: this.$router });
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#btn-imprimir,
#btn-fechar {
  padding: 3px 12px 3px 12px;
}
</style>
