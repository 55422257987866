var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "3" } }, [_vm._v("Número do CPF:")]),
          _c("v-col", { attrs: { cols: "9" } }, [_vm._v(_vm._s(_vm.cpf))])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "3" } }, [_vm._v("Nome do Cliente:")]),
          _c("v-col", { attrs: { cols: "9" } }, [
            _vm._v(_vm._s(_vm.nomeCompleto))
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Produtos Selecionados")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: [..._vm.style.colLeftClasses, "form-result-label"],
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("Produto")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colLeftClasses, "form-result-label"],
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("Número da Proposta")]
          )
        ],
        1
      ),
      _vm._l(_vm.produtos, function(item) {
        return _c(
          "v-row",
          {
            key: item.text,
            staticClass: "form-row",
            attrs: { "no-gutters": "" }
          },
          [
            _c(
              "v-col",
              {
                class: _vm.style.colLeftClasses,
                attrs: { "align-self": _vm.style.alignSelf }
              },
              [_vm._v(_vm._s(item.text))]
            ),
            _c(
              "v-col",
              {
                class: _vm.style.colRightClasses,
                attrs: { "align-self": _vm.style.alignSelf }
              },
              [_vm._v(_vm._s(item.proposta))]
            )
          ],
          1
        )
      }),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-right my-3" }, [
            _c(
              "a",
              {
                staticClass: "btn-azul mr-2",
                attrs: { id: "btn-imprimir" },
                on: { click: _vm.imprimir }
              },
              [_vm._v("Imprimir Formulário")]
            ),
            _c(
              "a",
              {
                staticClass: "btn-azul",
                attrs: { id: "btn-fechar" },
                on: { click: _vm.fechar }
              },
              [_vm._v("Fechar")]
            )
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }