var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _vm._v("\n      Número do CPF:\n    ")
          ]),
          _c("v-col", { attrs: { cols: "9" } }, [_vm._v(_vm._s(_vm.cpf))])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _vm._v("\n      Nome do Cliente:\n    ")
          ]),
          _c("v-col", { attrs: { cols: "9" } }, [
            _vm._v(_vm._s(_vm.nomeCompleto))
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Produtos Selecionados")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "ma-1" },
              _vm._l(_vm.produtos, function(item, index) {
                return _c(
                  "v-row",
                  {
                    key: item.text,
                    class: [
                      "form-row",
                      "text-center",
                      index === 0 ? "form-row-result-first" : ""
                    ],
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { "align-self": _vm.style.alignSelf } },
                      [_vm._v(_vm._s(item.text))]
                    )
                  ],
                  1
                )
              }),
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-center my-3" }, [
            _c(
              "a",
              {
                staticClass: "btn-azul",
                attrs: { id: "btn-solicitar" },
                on: { click: _vm.solicitar }
              },
              [_vm._v("Solicitar Avaliação")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }