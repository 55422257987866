<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col cols="3">
        Número do CPF:
      </v-col>
      <v-col cols="9">{{ cpf }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        Nome do Cliente:
      </v-col>
      <v-col cols="9">{{ nomeCompleto }}</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Produtos Selecionados</div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="form-row">
        <div class="ma-1">
          <v-row
            no-gutters
            :class="['form-row', 'text-center', index === 0 ? 'form-row-result-first' : '']"
            v-for="(item, index) in produtos"
            :key="item.text"
          >
            <v-col :align-self="style.alignSelf">{{ item.text }}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="text-center my-3">
        <a @click="solicitar" class="btn-azul" id="btn-solicitar">Solicitar Avaliação</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import ROUTERS from "../../../routes/router-constants";
import { mapActions } from "vuex";
import { mapAvaliacaoRiscoFields } from "@/store/modules/caixaqui-avaliacao-risco";

export default {
  name: "CaixaquiAvaliacaoRiscosProdutosSelecionados",

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  }),
  computed: {
    ...mapAvaliacaoRiscoFields([
      "cpf",
      "nomeCompleto",
      "produtos.creditoImobiliario",
      "produtos.consignacao",
      "produtos.cartaoCredito",
      "produtos.contaChequeAzul",
      "produtos.consigPortabilidade",
      "produtos.chequeAzulIsolado"
    ]),
    produtos() {
      return [
        { text: "Crédito Imobiliário", value: this.creditoImobiliario },
        { text: "Consignação", value: this.consignacao },
        { text: "Cartão de Crédito", value: this.cartaoCredito },
        { text: "Conta com Cheque Azul(CROT)", value: this.contaChequeAzul },
        { text: "Consignação Portabilidade", value: this.consigPortabilidade },
        { text: "Cheque Azul Isolado", value: this.chequeAzulIsolado }
      ].filter(produto => produto.value);
    }
  },
  methods: {
    ...mapActions("controller", ["solicitarAvaliacaoRisco"]),
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    solicitar() {
      this.solicitarAvaliacaoRisco().then(() => {
        this.$router.push({ name: ROUTERS.caixaqui.avaliacaoRiscos.produtosSelecProposta.name });
      });
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
#btn-solicitar {
  padding: 3px 12px 3px 12px;
}
</style>
